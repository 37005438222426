<template>
  <v-text-field
    ref="number"
    v-model="number"
    v-mask="'##############################'"
    :label="label"
    :rules="computedRules"
    :prepend-icon="prependIcon"
    :disabled="disabled"
    :messages="messages"
    :hint="hint"
    :persistent-hint="persistentHint"
    :error-messages="errorMessages"
    :success="success"
    @blur="onBlur"
  >
    <template #prepend-inner>
      <v-dialog v-model="countryDialog" max-width="500px">
        <template #activator="{ on }">
          <v-btn text class="px-2 mb-1" :disabled="disabled" v-on="on">
            <img
              :src="require(`@/assets/images/flags/${country.id.toLowerCase()}.svg`)"
              height="30px"
              class="c-flag icon"
            />
            +{{ country.phone }}
          </v-btn>
        </template>

        <v-card>
          <v-card-title>Select country</v-card-title>
          <v-card-text>
            <v-container>
              <v-autocomplete
                :items="countries"
                autofocus
                item-text="name"
                return-object
                hide-details
                class="pt-0"
                prepend-icon="fa-globe"
                :placeholder="$t('searchCountryPlaceholder')"
                @input="updateCountryCode"
              >
                <template #selection>
                  <div />
                </template>

                <template #item="{ item }">
                  <v-list-item-icon>
                    <img
                      :src="require(`@/assets/images/flags/${item.id.toLowerCase()}.svg`)"
                      height="30px"
                      class="c-flag icon"
                    />
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title> {{ item.name }} (+{{ item.phone }}) </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="countryDialog = null">{{ $t('cancel') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-text-field>
</template>

<script>
import countries from '@/lib/ui/countryPhoneNumbers.json';
import api from '@/util/api';
import is from '@/lib/util/is';

let savedCountryFromIP = null;

export default {
  name: 'PhoneField',

  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({
        extension: '',
        number: '',
        complete: '',
      }),
    },

    label: {
      type: String,
      required: false,
      default: '',
    },

    rules: {
      type: Array,
      required: false,
      default: () => [],
    },

    prependIcon: {
      type: String,
      required: false,
      default: null,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    messages: {
      type: [String, Array],
      required: false,
      default: () => [],
    },

    hint: {
      type: String,
      required: false,
      default: undefined,
    },

    persistentHint: {
      type: Boolean,
      required: false,
      default: false,
    },

    errorMessages: {
      type: [String, Array],
      required: false,
      default: () => [],
    },

    success: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: (self) => ({
    country: null,
    number: self.value && self.value.number ? self.value.number : '',
    countrySearch: '',
    countryDialog: null,

    countries,
  }),

  computed: {
    computedRules() {
      return this.rules.map((rule) => () => rule(this.value));
    },

    valid() {
      return !!this.country && this.$refs.number.valid;
    },
  },

  watch: {
    value() {
      this.setValue();
    },

    number() {
      this.onChange();
    },
  },

  created() {
    this.setValue();
  },

  methods: {
    setValue() {
      if (is.defined(this.value)) {
        if (this.value.number) {
          this.number = this.value.number;
        }

        if (this.value.extension) {
          const country = countries.find((country) => country.phone === this.value.extension);
          if (country) {
            this.country = country;
          }
        } else {
          this.resetCountry();
        }
      } else {
        this.number = '';
        this.resetCountry();
      }
    },

    resetCountry() {
      if (savedCountryFromIP) {
        this.country = savedCountryFromIP;
      } else {
        this.country = countries.find((country) => country.id.toLowerCase() === 'us');
        this.getCountryFromIp();
      }
    },

    getCountryFromIp() {
      api
        .getGeoAddress()
        .then((res) => {
          const countryFromIP = res.country.toLowerCase();
          const country = countries.find((country) => country.id.toLowerCase() === countryFromIP);
          if (country) {
            savedCountryFromIP = country;
            this.updateCountryCode(country);
          }
        })
        .catch(() => {
          // Do nothing
        });
    },

    updateCountryCode(country) {
      this.countryDialog = null;
      this.userSearch = '';
      this.country = country;
      this.onChange();
    },

    onChange() {
      const extension = this.country.phone;
      const number = this.number;
      this.$emit('input', {
        extension,
        number,
        complete: '+' + extension + number,
      });
    },

    onBlur(e) {
      this.$emit('blur', e);
    },

    validate() {
      return this.$refs.number.validate();
    },
  },
};
</script>

<style></style>
