<template>
  <div>
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="6" xl="4">
          <alert-icons />

          <v-container class="px-0 text-center text-sm-start">
            <v-form v-if="!success" ref="signupForm" v-model="valid" @submit.prevent="signup">
              <h2 class="title gray600--text text-uppercase">{{ $t('welcome') }}</h2>
              <h1 class="display-1">{{ $t('registerYourCompany') }}</h1>
              <a href="https://teamalert.net">{{ $t('iJustGotHere') }}</a>
              <p class="mt-6">
                {{ $t('alreadyUser') }}
                <v-btn to="/login" class="ml-3">
                  {{ $t('login') }}
                </v-btn>
              </p>

              <v-text-field
                v-model="companyName"
                :label="$t('companyName')"
                :rules="companyNameRules"
                :counter="100"
                validate-on-blur
                prepend-icon="fa-building fa-fw"
                autofocus
                :hint="$t('companyNameHelperText')"
                class="my-8 my-md-12"
              />

              <v-text-field
                v-model="adminName"
                :label="$t('yourName')"
                :rules="adminNameRules"
                :counter="100"
                validate-on-blur
                prepend-icon="fa-user fa-fw"
                :hint="$t('adminNameHelperText')"
                class="my-8 my-md-12"
              />

              <v-text-field
                v-model="adminEmail"
                :label="$t('email')"
                :rules="adminEmailRules"
                validate-on-blur
                prepend-icon="fa-at fa-fw"
                type="email"
                :hint="$t('emailHelperText')"
                class="my-8 my-md-12"
              />

              <phone-field
                v-model="adminMobile"
                :label="$t('phone')"
                :rules="adminMobileRules"
                validate-on-blur
                prepend-icon="fa-mobile fa-fw"
                :hint="$t('phoneHelperText')"
                class="my-8 my-md-12"
              />

              <div class="d-flex align-center">
                <v-icon class="ml-n1 mr-2">fa-file-certificate fa-fw</v-icon>

                <span>
                  <span>{{ $t('eulaConfirm') }} </span>
                  <a @click.prevent="showEULA">{{ $t('eula') }}</a>
                </span>

                <v-btn icon class="ml-3" @click="eulaConfirmed = !eulaConfirmed">
                  <div v-if="eulaConfirmed" key="eulaConfirmed">
                    <v-icon color="primary">fa-check-square</v-icon>
                  </div>
                  <div v-else key="eulaNotConfirmed">
                    <v-icon>far fa-square</v-icon>
                  </div>
                </v-btn>
              </div>

              <div ref="captchaWrapper" class="d-flex justify-center my-12" />

              <div class="d-flex justify-center justify-sm-end py-3">
                <v-btn
                  type="submit"
                  color="primary"
                  :loading="loading"
                  :block="$vuetify.breakpoint.xsOnly"
                  :disabled="!valid || loading"
                  @click.prevent="signup"
                >
                  {{ $t('register') }}
                </v-btn>
              </div>
            </v-form>

            <div v-else>
              <h2 class="title gray600--text text-uppercase">
                {{ $t('success') }}
              </h2>
              <h1 class="display-1">{{ $t('registrationSuccess') }}</h1>
              <div class="d-flex justify-center py-3">
                <v-icon color="primary" x-large>fa-arrow-right</v-icon>
                <div class="mx-3" />
                <v-icon color="primary" x-large>fa-envelope</v-icon>
              </div>
              <p>{{ $t('registrationSuccessMsg') }}</p>
            </div>

            <v-dialog v-model="eulaDialog" max-width="800px">
              <v-card>
                <v-card-title>{{ $t('eula') }}</v-card-title>
                <v-card-text>
                  <iframe
                    src="https://teamalert.net/terms?hideui=true"
                    frameborder="0"
                    height="500px"
                  />
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="primary" @click="eulaDialog = false">
                    {{ $t('close') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AlertIcons from '@/components/AlertIcons';
import PhoneField from '@/components/PhoneField';
import is from '@/lib/util/is';
import { translate as t } from '@/util/i18n';
import messageDialog from '@/components/dialog/messageDialog';
import { isRequired, minimum, maximum, isValid } from '@/consts/inputRules';
import isEmailValid from '@/lib/util/isEmailValid';
import safeGet from '@/lib/util/safeGetCb';
import errorMessage from '@/util/errorMessage';
import api from '@/util/api';
import appSettings from '@/appSettings';

const companyNameRules = [
  (name) => isRequired('name', name),
  (name) => minimum('name', name, 3),
  (name) => maximum('name', name, 100),
];
const adminNameRules = [
  (name) => isRequired('name', name),
  (name) => minimum('name', name, 3),
  (name) => maximum('name', name, 100),
];
const adminEmailRules = [
  (email) => isRequired('email', email),
  (email) => isValid('email', email, Boolean(isEmailValid(email))),
];
const adminMobileRules = [
  (phone) => isRequired('phone', phone && phone.number),
  (phone) =>
    isValid('phone', phone && phone.complete, Boolean(phone && phone.extension && phone.number)),
];

export default {
  name: 'Signup',

  components: {
    AlertIcons,
    PhoneField,
  },

  data: () => ({
    success: false,
    eulaDialog: false,
    valid: false,
    loading: false,

    companyNameRules,
    adminNameRules,
    adminEmailRules,
    adminMobileRules,

    companyName: '',
    adminName: '',
    adminEmail: '',
    adminMobile: null,
    eulaConfirmed: false,
  }),

  mounted() {
    const captcha = window.grecaptcha;

    if (captcha) {
      captcha.ready(() => {
        captcha.render(this.$refs.captchaWrapper, {
          sitekey: appSettings.grecaptchaKey,
        });
      });
    }
  },

  methods: {
    showEULA() {
      this.eulaDialog = true;
    },

    signup() {
      this.loading = true;

      let captchaResponseToken = '';
      const captcha = window.grecaptcha;
      if (captcha && is.function(captcha.getResponse)) {
        captchaResponseToken = captcha.getResponse();
      }

      if (captchaResponseToken.length < 1) {
        messageDialog(t('missingCaptcha'), t('missingCaptchaMsg'), t('ok'), 'warning').open();

        this.loading = false;
      } else if (!this.eulaConfirmed) {
        messageDialog(t('eula'), t('invalidEULA'), t('ok'), 'warning').open();

        this.loading = false;
      } else {
        api
          .signup({
            companyName: this.companyName,
            adminName: this.adminName,
            adminEmail: this.adminEmail,
            adminMobile: this.adminMobile.complete,
            eulaConfirmed: this.eulaConfirmed,
            captcha: captchaResponseToken,
          })
          .then(() => {
            // Register in hubspot
            const nameParts = this.adminName.trim().split(' ');
            let firstName;
            let lastName;
            if (nameParts.length > 1) {
              lastName = nameParts.pop();
              firstName = nameParts.join(' ');
            } else {
              firstName = nameParts[0];
              lastName = nameParts[0];
            }
            api.registerInHubSpot({
              firstname: firstName,
              lastname: lastName,
              company: this.companyName,
              email: this.adminEmail,
              phone: this.adminMobile.complete,
            });
            this.loading = false;
            this.success = true;
            // window.dataLayer = window.dataLayer || [];
            // window.dataLayer.push({ event: 'userRegistration' });
          })
          .catch((res) => {
            this.loading = false;

            const captcha = window.grecaptcha;
            if (captcha) {
              captcha.ready(() => {
                captcha.reset();
              });
            }

            let errMsg = safeGet(() => res.response.Errors[0].Details);
            if (errMsg && errMsg.indexOf('already exists') >= 0) {
              messageDialog(
                t('signupUserAlreadyExists'),
                t('signupUserAlreadyExistsMsg'),
                t('ok'),
                'warning',
              ).open();
            } else {
              errorMessage(t('registrationError'), t('registrationErrorMsg'), res);
            }
          });
      }
    },
  },
};
</script>
